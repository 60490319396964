/**
 * Регулярка для определения мобильного браузера
 */
export const MOBILE_USER_AGENT_REGEXP = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini/i;

/** @ignore */
export const MOBILE_ORIGIN = 'https://touch.mail.ru';

/** @ignore */
export const DESKTOP_ORIGIN = 'https://e.mail.ru';

/** @ignore */
export const CORSAPI_ORIGIN = 'https://corsapi.imgsmail.ru';

/** @ignore */
export const OAUTH_LIB_URL = 'https://oauth.mail.ru/sdk/v0.17.0/oauth.js';

/** @ignore */
export const AUTHGATE_LIB_URL = 'https://img.imgsmail.ru/hb/e.mail.ru/auth.gate/2.10.0/authGate.js';
